@use '@angular/material' as mat;
@use 'sass:map';

@mixin theme($theme) {
  $config: mat.get-color-config($theme);
  $background: map.get($config, background);
  $typography: mat.get-typography-config($theme);

  .layout-title {
    color: mat.get-color-from-palette($background, nm-layout-title);
    font-size: mat.font-size($typography, title-large);
    font-weight: mat.font-weight($typography, title-large);
    letter-spacing: mat.letter-spacing($typography, title-large);
    line-height: mat.line-height($typography, title-large);
  }

  .layout-picture {
    height: 240px;
    display: flex;
    align-items: flex-end;
    padding: 16px;

    background-size: cover;
    background-position: center;

    .layout-store {
      color: mat.get-color-from-palette($background, nm-layout-store);
      font-size: mat.font-size($typography, headline-large-400);
      font-weight: mat.font-weight($typography, headline-large-400);
      letter-spacing: mat.letter-spacing($typography, headline-large-400);
      line-height: mat.line-height($typography, headline-large-400);
    }

    .layout-description {
      color: mat.get-color-from-palette($background, nm-layout-store);
      font-size: mat.font-size($typography, body-medium);
      font-weight: mat.font-weight($typography, body-medium);
      letter-spacing: mat.letter-spacing($typography, body-medium);
      line-height: mat.line-height($typography, body-medium);
    }
  }
}

.layout-title-block {
  display: flex;
  padding: 0 16px;
  gap: 8px;
  flex-direction: row;
  align-items: center;
  height: 64px;
}
