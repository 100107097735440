@use '@angular/material' as mat;
@use 'sass:map';

:host {
  display: block;
  width: 100%;
}

@mixin nm-form-field($config) {
  $background: map.get($config, background);
  $foreground: map.get($config, foreground);
  $border-color: mat.get-color-from-palette($background, nm-form-field-border);

  background-color: mat.get-color-from-palette($background, nm-form-field);
  box-shadow: inset 0 0 0 1px $border-color;
  color: mat.get-color-from-palette($foreground, nm-form-field-text);

  .icon-wrapper-color, div[icons] {
    color: mat.get-color-from-palette($foreground, nm-form-field-icon);
  }

  .prefix, .suffix {
    color: mat.get-color-from-palette($foreground, nm-form-field-prefix);
    padding: 4px 0;
  }

  input, select, textarea {
    border: none;
    background: none;
    color: mat.get-color-from-palette($foreground, nm-form-field-text);
    outline: none;
    padding: 12px 0;

    &::placeholder {
      color: mat.get-color-from-palette($foreground, nm-form-field-placeholder);
    }
  }

  textarea {
    margin: 12px 0;
    padding: 0;
  }

  &:hover {
    box-shadow: inset 0 0 0 1px mat.get-color-from-palette($background, nm-form-field-hovered);
  }

  &:active, &:focus-within, &.focused {
    box-shadow: inset 0 0 0 2px mat.get-color-from-palette($background, nm-form-field-active);
  }

  &.error {
    box-shadow: inset 0 0 0 1px mat.get-color-from-palette($background, nm-form-field-error);
  }

  &.nm-form-field-disabled {
    box-shadow: inset 0 0 0 1px mat.get-color-from-palette($background, nm-form-field-disabled);
    color: mat.get-color-from-palette($foreground, nm-form-field-disabled);
    pointer-events: none;

    input, select, textarea {
      color: mat.get-color-from-palette($foreground, nm-form-field-disabled);

      &::placeholder {
        color: mat.get-color-from-palette($foreground, nm-form-field-disabled);
      }
    }

    .icon-wrapper {
      color: mat.get-color-from-palette($foreground, nm-form-field-disabled);
    }
  }
}

@mixin theme ($theme) {
  $config: mat.get-color-config($theme);
  $typography: mat.get-typography-config($theme);

  .nm-form-field {
    @include nm-form-field($config);

    border-radius: 8px;

    .content-wrapper {
      display: flex;
      align-items: center;
      min-height: 40px;

      input, select, textarea {
        overflow: hidden;
        width: 100%;
        text-overflow: ellipsis;
      }

      .icon-wrapper {
        display: flex;
        align-items: center;
        padding: 8px;

        button {
          display: flex;
        }
      }

      .prefix {
        display: inline-table;
        flex-shrink: 0;
        padding: 4px;

        &:first-child {
          padding-left: 12px;
        }
      }

      .suffix {
        overflow: hidden;
        min-width: 20px;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding: 4px 8px 4px 4px;

        &:last-child {
          padding-right: 8px;
        }
      }

      > div[icons] {
        display: flex;
        flex-shrink: 0;
        align-items: baseline;
        padding: 8px;
        gap: 8px;

        &:last-child {
          padding-right: 8px;
        }
      }

      .text-wrapper {
        position: relative;
        width: 100%;
        flex: 1;

        &:first-child {
          padding-left: 12px;
        }

        &:last-child {
          padding-right: 12px;
        }

        &:has(.mat-select) {
          padding-left: 12px;
          padding-right: 8px;
        }

        // &:has(.value-wrapper),
        // &:has(textarea),
        // &:has(select),
        // &:has(input) {
        //   padding-left: 0;
        //   padding-right: 0;
        // }
      }

      .icon-wrapper + .prefix,
      .icon-wrapper + div[icons],
      .prefix + .text-wrapper,
      .suffix + div[icons],
      .suffix + .icon-wrapper {
        padding-left: 0;
      }

      .icon-wrapper + .prefix,
      .icon-wrapper + div[icons],
      .prefix + .text-wrapper,
      .suffix + div[icons],
      .suffix + .icon-wrapper {
        padding-left: 0;
      }

      .text-wrapper, .prefix {
        overflow: hidden;
        min-width: 20px;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    font-size: mat.font-size($typography, nm-form-field);
    font-weight: mat.font-weight($typography, nm-form-field);
    letter-spacing: mat.letter-spacing($typography, nm-form-field);
    line-height: mat.line-height($typography, input);
  }
}
