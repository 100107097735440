@use '@angular/material' as mat;
@use 'sass:map';

:host {
  display: flex;
  overflow: hidden;
  border-radius: 8px;
  width: fit-content;

  &:not(.no-shadow) {
    &.elevated {
      box-shadow: 0 1px 2px 0 rgba(0,0,0,0.3), 0 1px 3px 1px rgba(0,0,0,0.15); // nm-chip-elevated-shadow
    }

    &.dragged, &.dragged.elevated {
      box-shadow: 0 2px 3px 0 rgba(0,0,0,0.3), 0 6px 10px 4px rgba(0,0,0,0.15); // nm-chip-dragged-shadow
    }

    &:not(.elevated).selected:hover {
      box-shadow: 0 1px 2px 0 rgba(0,0,0,0.3), 0 1px 3px 1px rgba(0,0,0,0.15); // nm-chip-elevated-shadow
    }
  }
}

@mixin theme ($theme) {
  $config: mat.get-color-config($theme);
  $foreground: map.get($config, foreground);
  $background: map.get($config, background);
  $typography: mat.get-typography-config($theme);

  .mat-chip.mat-standard-chip {
    position: relative;
    display: flex;
    overflow: hidden;
    box-sizing: border-box;
    align-items: center;
    padding: 5px 11px;
    border: 1px solid mat.get-color-from-palette($background, nm-chip-border);
    border-radius: 8px;
    column-gap: 8px;
    cursor: default;
    font-size: mat.font-size($typography, nm-chip);
    font-weight: mat.font-weight($typography, nm-chip);
    letter-spacing: mat.letter-spacing($typography, nm-chip);
    line-height: mat.line-height($typography, nm-chip);

    &.nm-chip-icon-right {
      padding-right: 7px;
    }

    &.nm-chip-icon-left {
      padding-left: 7px;
    }

    &.mat-primary.mat-chip-selected:not(.something), &.nm-chip-elevated:not(.something) {
      padding: 6px 12px;
      border: none;

      &.nm-chip-icon-right {
        padding-right: 8px;
      }

      &.nm-chip-icon-left {
        padding-left: 8px;
      }
    }

    &.mat-primary.mat-chip-selected:not(.something) {
      background-color: mat.get-color-from-palette($background, nm-chip-selected);
      color: mat.get-color-from-palette($foreground, nm-chip-selected-text);

      &.mat-chip-disabled {
        background-color: mat.get-color-from-palette($background, nm-chip-selected-disabled);
        color: mat.get-color-from-palette($foreground, nm-chip-selected-disabled-text);
      }
    }

    &.mat-chip-disabled:not(.something) {
      border-color: mat.get-color-from-palette($background, nm-chip-disabled-border);
      background-color: initial;
      color: mat.get-color-from-palette($foreground, nm-chip-disabled-text);
      opacity: 1;

      &.nm-chip-elevated:not(.something) {
        background-color: mat.get-color-from-palette($background, nm-chip-elevated-disabled);
        color: mat.get-color-from-palette($foreground, nm-chip-elevated-disabled-text);
      }
    }

    .mat-chip-ripple {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      overflow: hidden;
      pointer-events: none;
      transform: translateZ(0);
    }

    &:not(.mat-chip-disabled) {
      background-color: initial;
      color: mat.get-color-from-palette($foreground, nm-chip-text);

      &:hover {
        background-color: mat.get-color-from-palette($background, nm-chip);

        .mat-chip-ripple {
          background-color: mat.get-color-from-palette($background, nm-chip-hovered-ripple);
        }
      }

      &.mat-chip-selected:hover {
        background-color: mat.get-color-from-palette($background, nm-chip-selected);

        .mat-chip-ripple {
          background-color: mat.get-color-from-palette($background, nm-chip-selected-hovered-ripple);
        }
      }

      &:focus {
        background-color: mat.get-color-from-palette($background, nm-chip);

        .mat-chip-ripple {
          background-color: mat.get-color-from-palette($background, nm-chip-focused-ripple);
        }
      }

      &.mat-chip-selected:focus {
        background-color: mat.get-color-from-palette($background, nm-chip-selected);

        .mat-chip-ripple {
          background-color: mat.get-color-from-palette($background, nm-chip-selected-focused-ripple);
        }
      }

      &:active:not(.something) {
        background-color: mat.get-color-from-palette($background, nm-chip);
        box-shadow: none;

        .mat-chip-ripple {
          background-color: mat.get-color-from-palette($background, nm-chip-pressed-ripple);
        }
      }

      &.mat-chip-selected:active:not(.something) {
        background-color: mat.get-color-from-palette($background, nm-chip-selected);

        .mat-chip-ripple {
          background-color: mat.get-color-from-palette($background, nm-chip-selected-pressed-ripple);
        }
      }

      &.nm-chip-dragged {
        background-color: mat.get-color-from-palette($background, nm-chip);

        .mat-chip-ripple {
          background-color: mat.get-color-from-palette($background, nm-chip-dragged-ripple);
        }
      }

      &.mat-chip-selected.nm-chip-dragged {
        background-color: mat.get-color-from-palette($background, nm-chip-selected);

        .mat-chip-ripple {
          background-color: mat.get-color-from-palette($background, nm-chip-selected-dragged-ripple);
        }
      }

      .right-icon {
        cursor: pointer;
      }
    }

    .chip-text {
      overflow: hidden;
      flex-grow: 0;
      text-overflow: ellipsis;
      white-space: nowrap;

      &::after {
        display: block;
        content: '';
      }
    }

    .nm-avatar {
      width: 24px;
      height: 24px;
      margin-top: -2px;
      margin-bottom: -2px;
      margin-left: -8px;
    }

    .left-image {
      width: 18px;
      height: 18px;
      margin-left: -4px;
      object-fit: cover;
    }

    nm-svg-icon {
      min-width: 18px;
    }
  }
}
