@use '@angular/material' as mat;
@use 'sass:map';

@import 'typography';

@mixin theme ($theme) {
  $config: mat.get-color-config($theme);
  $foreground: map.get($config, foreground);
  $typography: mat.get-typography-config($theme);

  $nm-alert-information: mat.get-color-from-palette($foreground, nm-alert-information);
  $nm-alert-information-timer: mat.get-color-from-palette($foreground, nm-alert-information-timer);
  $nm-alert-information-color: mat.get-color-from-palette($foreground, nm-alert-information-color);
  $nm-alert-success: mat.get-color-from-palette($foreground, nm-alert-success);
  $nm-alert-success-timer: mat.get-color-from-palette($foreground, nm-alert-success-timer);
  $nm-alert-success-color: mat.get-color-from-palette($foreground, nm-alert-success-color);
  $nm-alert-warning: mat.get-color-from-palette($foreground, nm-alert-warning);
  $nm-alert-warning-timer: mat.get-color-from-palette($foreground, nm-alert-warning-timer);
  $nm-alert-warning-color: mat.get-color-from-palette($foreground, nm-alert-warning-color);
  $nm-alert-error: mat.get-color-from-palette($foreground, nm-alert-error);
  $nm-alert-error-timer: mat.get-color-from-palette($foreground, nm-alert-error-timer);
  $nm-alert-error-color: mat.get-color-from-palette($foreground, nm-alert-error-color);
  $nm-alert-close: mat.get-color-from-palette($foreground, nm-alert-close);

  .nm-alert {
    position: relative;
    display: flex;
    overflow: hidden;
    width: 100%;
    max-width: 600px;
    min-height: 52px;
    padding: 8px;
    border-radius: 4px;

    .timer {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 4px;
      animation-duration: 3.25s;
      animation-fill-mode: forwards;
      animation-name: slide-in;
      animation-timing-function: linear;
    }

    &.information {
      background-color: $nm-alert-information;

      .icon, .title, .description, .link {
        color: $nm-alert-information-color;
      }

      .timer {
        background-color: $nm-alert-information-timer;
      }
    }

    &.success {
      background-color: $nm-alert-success;

      .icon, .title, .description, .link {
        color: $nm-alert-success-color;
      }

      .timer {
        background-color: $nm-alert-success-timer;
      }
    }

    &.warning {
      background-color: $nm-alert-warning;

      .icon, .title, .description, .link {
        color: $nm-alert-warning-color;
      }

      .timer {
        background-color: $nm-alert-warning-timer;
      }
    }

    &.error {
      background-color: $nm-alert-error;

      .icon, .title, .description, .link {
        color: $nm-alert-error-color;
      }

      .timer {
        background-color: $nm-alert-error-timer;
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      padding: 8px;
    }

    .title {
      font-size: mat.font-size($typography, title-medium);
      font-weight: mat.font-weight($typography, title-medium);
      letter-spacing: mat.letter-spacing($typography, title-medium);
      line-height: mat.line-height($typography, title-medium);
    }

    .description, .link {
      font-size: mat.font-size($typography, body-medium);
      font-weight: mat.font-weight($typography, body-medium);
      letter-spacing: mat.letter-spacing($typography, body-medium);
      line-height: mat.line-height($typography, body-medium);
    }

    .link {
      text-decoration: underline;
      white-space: nowrap;
    }

    .icon {
      margin: 8px;
    }

    .close {
      margin-left: auto;

      button {
        color: $nm-alert-close;
      }
    }

    @keyframes slide-in {
      to {
        transform: translateX(100%);
      }
    }
  }
}
