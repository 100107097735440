.notifications {
  display: grid;
  max-height: calc(var(--vh, 1vh) * 100);
  padding: 0 16px;
  margin-left: auto;
  grid-row-gap: 12px;
  grid-template-columns: minmax(0, 400px);
  overflow-x: hidden;
  overflow-y: auto;
  scroll-padding: 24px 0;
  scroll-snap-type: y mandatory;
}

.notifications__item {
  scroll-snap-align: start;

  &:first-of-type {
    padding-top: 16px;
  }

  &:last-of-type {
    padding-bottom: 16px;
  }
}
