.nm-textarea {
  display: flex;
  flex-direction: column;
  row-gap: 4px;

  textarea {
    resize: none;
  }

  .textarea-form-field {
    ::ng-deep .nm-form-field {
      align-items: flex-start;

      .left-block, .content-wrapper {
        align-items: flex-start;
      }

      .right-block {
        align-items: flex-start;
      }
    }
  }
}
