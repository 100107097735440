@use '@angular/material' as mat;
@use 'sass:map';

@use 'app/shared/component/input/input.component';
@use 'app/shared/component/button/button.component';
@use 'app/shared/component/icon-button/icon-button.component';
@use 'app/shared/component/radio/radio.component';
@use 'app/shared/component/checkbox/checkbox.component';
@use 'app/shared/component/form-field/form-field.component';
@use 'app/shared/component/control-label/control-label.component';
@use 'app/shared/component/divider/divider.component';
@use 'app/shared/component/chip/chip.component';
@use 'app/shared/component/control-hint/control-hint.component';
@use 'app/shared/component/avatar/avatar.component';
@use 'app/shared/component/alert/alert.component';
@use 'app/shared/component/notifications/notifications.component';
@use 'app/shared/component/textarea/textarea.component';
@use 'app/shared/component/chips/chips.component';

@use 'app/modules/error/page/page-404/page-404.component';
@use 'app/modules/pages/booking/booking.component';
@use 'app/layout/layout/layout.component';

@import 'assets/style/media-vars';
@import '~normalize.css';

@import 'assets/style/fonts.css';
@import 'assets/style/typography.scss';
@import 'assets/style/colors.scss';
@import 'assets/style/theme.scss';
@import 'assets/style/app';

@mixin nomia-theme($theme) {
  @include input.theme($theme);
  @include button.theme($theme);
  @include icon-button.theme($theme);
  @include chip.theme($theme);
  @include control-hint.theme($theme);
  @include radio.theme($theme);
  @include checkbox.theme($theme);
  @include form-field.theme($theme);
  @include control-label.theme($theme);
  @include divider.theme($theme);
  @include avatar.theme($theme);
  @include alert.theme($theme);
  //@include notifications.theme($theme);
  //@include textarea.theme($theme);
  @include booking.theme($theme);
  @include page-404.theme($theme);
  @include layout.theme($theme);
  @include chips.theme($theme);
}

@include nomia-theme($theme);
@include mat.all-component-themes($theme);

@include mat.core($nomia-typography);

body {
  overscroll-behavior-y: none;
  position: fixed;
  overflow: hidden;
  width: 100vw;
  height: 100svh;

  $config: mat.get-color-config($theme);
  $background: map.get($config, background);
  background-color: mat.get-color-from-palette($background, base-layout-back);
}

.application-loading-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.spinner {
  width: 24px;
  height: 24px;
  border: 3px solid rgb(0, 92, 188);
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
