@use '@angular/material' as mat;
@use 'sass:map';

:host {
  width: 100%;
}

@mixin theme ($theme) {
  $config: mat.get-color-config($theme);
  $foreground: map.get($config, foreground);
  $typography: mat.get-typography-config($theme);

  .nm-control-hint {
    display: flex;
    align-items: center;
    column-gap: 4px;
    font-size: mat.font-size($typography, nm-control-hint);
    font-weight: mat.font-weight($typography, nm-control-hint);
    letter-spacing: mat.letter-spacing($typography, nm-control-hint);
    line-height: mat.line-height($typography, nm-control-hint);

    .label-icon {
      flex-shrink: 0;
    }

    .label-text {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &.helper {
      color: mat.get-color-from-palette($foreground, nm-hint-helper);
    }

    &.error {
      color: mat.get-color-from-palette($foreground, nm-hint-error);
    }

    &.validation {
      color: mat.get-color-from-palette($foreground, nm-hint-validation);
    }
  }
}
