@use '@angular/material' as mat;
@use 'sass:map';

@mixin theme($theme) {
  $config: mat.get-color-config($theme);
  $background: map.get($config, background);
  $typography: mat.get-typography-config($theme);

  .nm-booking {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: auto;
  }

  .booking-banner {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: mat.get-color-from-palette($background, nm-booking-banner);
    padding: 16px;
    gap: 8px;
    border-radius: 16px;
    width: 100%;

    img {
      width: 96px;
      height: 96px;
    }

    nm-svg-icon {
      color: mat.get-color-from-palette($background, nm-booking-banner-icon);
    }

    .booking-banner-title {
      color: mat.get-color-from-palette($background, nm-booking-banner-title);
      font-size: mat.font-size($typography, headline-small);
      font-weight: mat.font-weight($typography, headline-small);
      letter-spacing: mat.letter-spacing($typography, headline-small);
      line-height: mat.line-height($typography, headline-small);
    }

    .booking-banner-desc {
      color: mat.get-color-from-palette($background, nm-booking-banner-desc);
      font-size: mat.font-size($typography, body-large-400);
      font-weight: mat.font-weight($typography, body-large-400);
      letter-spacing: mat.letter-spacing($typography, body-large-400);
      line-height: mat.line-height($typography, body-large-400);
    }
  }

  .booking-infos {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .booking-title {
      color: mat.get-color-from-palette($background, nm-booking-title);
      font-size: mat.font-size($typography, label-large);
      font-weight: mat.font-weight($typography, label-large);
      letter-spacing: mat.letter-spacing($typography, label-large);
      line-height: mat.line-height($typography, label-large);
    }

    .booking-info {
      display: flex;
      flex-direction: row;
      gap: 4px;

      .booking-text {
        color: mat.get-color-from-palette($background, nm-booking-text);
        font-size: mat.font-size($typography, body-small);
        font-weight: mat.font-weight($typography, body-small);
        letter-spacing: mat.letter-spacing($typography, body-small);
        line-height: mat.line-height($typography, body-small);
        overflow: hidden;

        &.booking-desc {
          color: mat.get-color-from-palette($background, nm-booking-desc);
          flex: 1;
          word-wrap: break-word;
        }
      }
    }
  }

  .logo-block {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 16px;

    .logo-text {
      color: #707070;
      font-size: mat.font-size($typography, body-large-400);
      font-weight: mat.font-weight($typography, body-large-400);
      letter-spacing: mat.letter-spacing($typography, body-large-400);
      line-height: mat.line-height($typography, body-large-400);
    }
  }
}


.booking {
  display: flex;
  padding: 0 16px;
  gap: 16px;
  align-items: center;
  flex-direction: column;
  overflow-y: auto;

  max-width: 632px;
  margin: 0 auto;
  width: 100%;
  min-width: 300px;

  nm-input {
    width: 100%;
  }
}

.booking-done {
  width: 100%;
  display: flex;
  gap: 16px;
  flex-direction: column;
}
