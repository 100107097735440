@use '@angular/material' as mat;
@use 'sass:map';

:host {
  display: flex;
}

@mixin theme ($theme) {
  $config: mat.get-color-config($theme);
  $foreground: map.get($config, foreground);
  $typography: mat.get-typography-config($theme);

  $nm-avatar-background: mat.get-color-from-palette($foreground, nm-avatar-background);
  $nm-avatar-color: mat.get-color-from-palette($foreground, nm-avatar-color);

  .nm-avatar {
    position: relative;
    display: inline-flex;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    background-color: $nm-avatar-background;

    &[disabled] {
      opacity: 0.38;
      cursor: default;
    }

    .title {
      color: $nm-avatar-color;

      &.small {
        font-size: mat.font-size($typography, label-small);
        font-weight: mat.font-weight($typography, label-small);
        letter-spacing: mat.letter-spacing($typography, label-small);
        line-height: mat.line-height($typography, label-small);
      }

      &.medium {
        font-size: mat.font-size($typography, label-medium);
        font-weight: mat.font-weight($typography, label-medium);
        letter-spacing: mat.letter-spacing($typography, label-medium);
        line-height: mat.line-height($typography, label-medium);
      }

      &.large {
        font-size: mat.font-size($typography, label-large);
        font-weight: mat.font-weight($typography, label-large);
        letter-spacing: mat.letter-spacing($typography, label-large);
        line-height: mat.line-height($typography, label-large);
      }
    }

    .icon {
      color: $nm-avatar-color;

      &.avatar-label {
        display: block;
        text-align: center;
        color: mat.get-color-from-palette($foreground, nm-avatat-icon-color);
      }
    }

    .image {
      position: absolute;
      display: block;
      object-fit: cover;
    }
  }
}
