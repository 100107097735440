@import 'media';

input[type='time']::-webkit-calendar-picker-indicator {
  background: none;

  pointer-events: none;
}

.custom-scrollbar {
  scrollbar-gutter: stable;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    width: 8px;
    min-height: 128px;
    border-radius: 8px;

    background-color: var(--OnSurface);

    &:hover {
      background-color: var(--Secondary);
    }
  }
}


@include Media($M) {
  .sidebar-position {
    transform: translate(100%, 0);
  }
}
